import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import MainLayout from './components/layout/MainLayout/MainLayout';
import MainPage from './components/views/MainPage/MainPage';
// import HomePage from './components/views/Homepage/Homepage';
import NotFound from './components/views/NotFound/NotFound';
import './styles/global.css';


const App = () => {
  return (
    <BrowserRouter>
      <MainLayout>
        <Switch>
          <Route exact path='/' component={MainPage} />
          <Route path='*' component={NotFound} />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
