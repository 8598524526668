import React, { Suspense } from 'react';
import { hydrate, render } from "react-dom";
// import ReactDOM from 'react-dom';
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.min.css';

import App from './App';
// import SuspenseLoading from './components/layout/SuspenseLoading/SuspenseLoading';

// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     supportedLngs: ['en', 'pl', 'cn'],
//     fallbackLng: 'en',
//     detection: {
//       order: [ 'cookie','htmlTag', 'localStorage','path', 'subdomain'],
//       caches: ['cookie'],
//     },
//     backend: {
//       loadPath: '/assets/locales/{{lng}}/translation.json',
//     },
// });

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

