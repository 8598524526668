import React from 'react';
import Aos from 'aos';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import allVersions from '../../../allVersions.json';
import "./AllVersions.css";
import background_img from '../../../media/square_background.png';
import icon from '../../../media/icon.png'
const AllVersions = () => {
    Aos.init({duration: 1500});
  return (
    <div className='allVersions_main_container' id="allVersions" data-aos="fade-left">
        {/* <img src={background_img} alt="Download latest version of QU4RTET-UI" className='bg_allVersions'/> */}
        <div className='container'>
            <h1>ALL VERSIONS OF QU4RTET-UI</h1>
            <Container>
                <Row>
                    <Col xl={6}>
                    <h1 className='platform_version_title'>Windows versions</h1>
                    {
                    allVersions.windowsVersions.map(item => (
                        <Col sm={12}  key={item.id}>
                            <a href={item.version_link}  className="version_item" download>
                            <img src={icon} alt="download QU4RTET-UI for windows" />
                            <span className='version_number'>{item.version_number} <span className='version_date'>{"("+item.version_date+")"}</span></span>
                            </a>
                        </Col>
                    ))
                    }
                    
                    </Col>
                    <Col xl={6}>
                    <h1 className='platform_version_title'>MacOS versions</h1>
                    {
                        allVersions.macVersions.map(item => (
                            <Col sm={12}  key={item.id}>
                                <a href={item.version_link}  className="version_item" download>
                                <img src={icon} alt="download QU4RTET-UI for mac" />
                                <span className='version_number'>{item.version_number} <span className='version_date'>{"("+item.version_date+")"}</span></span>
                                </a>
                            </Col>
                        ))
                    }
                    </Col>
                </Row>
        </Container>
        </div>
    </div>
  )
}

export default AllVersions