
import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const MainLayout = ({children}) => {
  const { t } = useTranslation();
  return(
  <div>
    <Header />
    {children}
    <Footer />
  </div>
  )
};

MainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default MainLayout;