import React from 'react';
import "./HomePage.css";

import allVersions from '../../../allVersions.json';
import home_bg from "../../../media/bg.png";

const HomePage = () => {
  return (
    <div className='homePage_main_container' id="home">
        <img src={home_bg} alt="Download latest version of QU4RTET-UI" className='home_background' />
        <div className='downloading_buttons_container'>
      <a href={allVersions.windowsVersions[0].version_link} className='download_button' download>
        Download Windows latest version ({allVersions.windowsVersions[0].version_number})
      </a>
      <a href={allVersions.macVersions[0].version_link} className='download_button' download>
        Download MacOS latest version ({allVersions.macVersions[0].version_number})
      </a>
    </div>
    </div>
  )
}

export default HomePage