import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';
import './MainPage.css';
import allVersions from '../../../allVersions.json';
import arrow from "../../../media/arrow.svg";
import HomePage from '../HomePage/HomePage';
import AllVersions from '../AllVersions/AllVersions';
import "./MainPage.css";
import UpdateVersion from '../UpdateVersion/UpdateVersion';
import Checksums from '../Checksums/Checksums';
import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';

const MainPage = () => {
  const { t } = useTranslation();
  Aos.init({duration: 1500});
return (
  <div className='mainPage_content'>
    <HomePage />
    <AllVersions />
    <UpdateVersion />
    <ReleaseNotes />
    <Checksums />
  </div>
)
};

MainPage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default MainPage;