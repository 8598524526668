import React from 'react'
import { Container, Row, Col, Tab, Nav, Badge, ListGroup  } from 'react-bootstrap';
import "./ReleaseNotes.css";
import {releaseNotes} from "./releseNotesData.json";
const ReleaseNotes = () => {
  return (
    <div className='checksums_main_container' id="releasenotes" data-aos="fade-left" >
        <div className='container'>
            <h1>QU4RTET-UI - Release Notes</h1>
        </div>
        <Container>
            <Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey={releaseNotes[0].id}>
                <Row>
                    <Col sm={2}>
                    <Nav variant="pills" bg="success" className="flex-column">
                        {releaseNotes.map((note, index) =>(
                            <Nav.Item key={note.id}>
                                <Nav.Link eventKey={note.id}>
                                    {note.versionNumber}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            {releaseNotes.map((note, index) =>(
                                <Tab.Pane key={note.id} eventKey={note.id}>
                                    <Badge bg="secondary">{note.versionName}</Badge>
                                    <ListGroup variant="flush">
                                        {note.versionFeatures.map(feature => (
                                            <ListGroup.Item key={feature.description}>
                                                <Badge bg="success" pill>
                                                    {feature.type}
                                                </Badge>
                                                {" "}
                                                {feature.description}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            </Row>
        </Container>
    </div>
  )
}

export default ReleaseNotes;