import React from 'react'
import { Link } from 'react-router-dom'
import "./NotFound.css"
const NotFound = () => {
  return (
    <div className='notfound_page'>
        <h1>Page not found</h1>
        <Link to="/" className="go_back">
            Go back 
        </Link>
    </div>
  )
}

export default NotFound