import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import logo from '../../../media/qu4rtet-logo.png';
import './Header.css';

// const languages = [
//   {
//     code: 'en',
//     name: 'English',
//     country_code: 'gb',
//   },
//   {
//     code: 'pl',
//     name: 'Polski',
//     country_code: 'pl',
//   },
// ];

const Header = () => {
  // const currentLanguageCode = cookies.get('i18next') || 'en';
  // const { t } = useTranslation();
  const [header, setHeader]= useState(false);
  const checkScroll = () => {
    if(window.scrollY >= 70) {
      setHeader(true)
    }
    else {
      setHeader(false)
    }
  };
  window.addEventListener("scroll", checkScroll);
    return(
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className={header ? 'main_navbar' : 'main_navbar main_header_height'}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="QU4RTET Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navigation_links_container">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#allVersions">All versions</Nav.Link>
              <Nav.Link href="#howToUpgrade">How to upgrade</Nav.Link>
              <Nav.Link href="#releasenotes">Release Notes</Nav.Link>
              <Nav.Link href="#checksum">Checksum</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Header;