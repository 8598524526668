import React, {useState} from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Aos from 'aos';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import allVersions from '../../../allVersions.json';
import icon from '../../../media/icon.png'
import check from '../../../media/check.svg';
// import copyIcon from '../../../media/copy.svg';
// import PC from '../../../media/pc.svg';

import "./Checksums.css"

const Checksums = () => {
    // const { t } = useTranslation();
  Aos.init({duration: 1500});

  const [copied, setCopied] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className='checksums_main_container' id="checksum" data-aos="fade-left">
        <div className='container'>
            <h1>Checksum for available versions {"(SHA-256)"}</h1>
        </div>
        <Container>
            <Row>
                <Col xl={6}>
                <h1 className='platform_version_title'>Windows versions</h1>
                {
                allVersions.windowsVersions.map(item => (
                    <CopyToClipboard key={item.check_sum} 
                    text={item.check_sum} 
                    onCopy={() => (
                        setCopied(item.check_sum), 
                        setOpenPopup(true),
                        setTimeout(() => {
                        setOpenPopup(false)
                    }, 2000))}
                    >
                    <div className='version_item'>
                    <img src={icon} alt="QU4RTET secure" /> 
                    <div>{item.version_number}: {item.check_sum}</div>
                    {/* {copied === item.check_sum ? <img src={copyIcon} alt="sha copy" /> : null} */}
                    </div>
                    </CopyToClipboard>
                ))
                }
                </Col>
                <Col xl={6}>
                <h1 className='platform_version_title'>MacOS versions</h1>
                {
                    allVersions.macVersions.map(item => (
                    <CopyToClipboard text={item.check_sum} key={item.check_sum} onCopy={() => (
                        setCopied(item.check_sum), 
                        setOpenPopup(true),
                        setTimeout(() => {
                        setOpenPopup(false)
                    }, 2000))}>
                        <div className='version_item'>
                         <div><img src={icon} alt="QU4RTET secure" />{item.version_number}: {item.check_sum}</div>
                        {/* {copied === item.check_sum ? <img src={copyIcon} alt="sha copy" /> : null} */}
                        </div>
                    </CopyToClipboard>
                    ))
                }
                </Col>
            </Row>
        </Container>
        {
          openPopup === true 
          ? 
          <div className='popup_copy'>
            <img src={check} alt="QU4RTET secure" className="check_img"/> 
            <span>Checksum copied</span>
          </div> 
          : 
          null
        }
    </div>
  )
}

export default Checksums