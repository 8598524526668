import React from 'react';
import Aos from 'aos';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PC from '../../../media/pc.svg';

import "./UpdateVersion.css";
const UpdateVersion = () => {
    Aos.init({duration: 1500});
  return (
    <div className='container updateVersion_main_container' id="howToUpgrade" data-aos="fade-right">
        <h1>Upgrade to latest version</h1>
        <Container>
        <Row>
            <Col lg={6}>
                <p className='update_text'>
                    In order to upgrade an existing installation, simply download and run the installer. It will detect the previous version and perform the upgrade. All the existing settings will be retained, and the application itself wіll be upgraded.
                </p>
            </Col>
            <Col lg={6} className="column_img"><img src={PC} alt="QU4RTET application" className='pc_image' /></Col>
        </Row>
        </Container>
    </div>
  )
}

export default UpdateVersion;